body {
  background-color: #151a1d;
  color: #f8f9fa;
  font-family: kbbody, serif;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

#loginPage {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;

  img {
    height: auto;
    max-width: 100%;
    width: 350px;
  }

  h4 {
    font-size: 29.5px;
    font-weight: 900;
    margin: 2rem 0;
    text-align: center;
    text-transform: uppercase;
  }
}

#galleryPage {
}

.btn-toolbar {
  justify-content: center;
  button.btn {
    align-self: center;
    font-size: 28px;
    line-height: 28px;
  }
}

input:focus::placeholder {
  color: transparent;
}

.react-toast-notifications__toast__content {
  display: flex;
  flex-direction: column;
  font-size: 18px !important;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 20px !important;
}

.react-toast-notifications__toast__dismiss-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.react-toast-notifications__toast__icon-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
}

.loading {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.react-photo-gallery--gallery {
  min-height: calc(100vh - 60px);
}
footer.copyright {
  border-top: 1px solid rgba(#8bc1fe, 0.1);
  color: #667;
  font-size: 13px;
  margin-top: auto;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  a {
    color: rgba(#8bc1fe, 0.6);
    font-weight: 300;
    transition: color 0.2s;
    text-decoration: none;
    &:hover {
      color: rgba(#8bc1fe, 0.8);
      transition: color 0.2s;
    }
  }
}
